// Higher order component, that renders the navbar(in web) and bottom navbar in mobile
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Switch } from 'react-router-dom';
import { privateRoutes } from '../routes';
import Main from '../components/atoms/Main';
import { BottomBar, Navbar, LayoutModalsConsumer } from '../components/organisms/layouts';
import { ModalContext, NavbarContext, GlobalContext, BookingHistoryContext } from '../utils/contexts';
import combineReducers from '../services/reducers/combineReducers'
import ekincareReducer from '../services/reducers/ekincareReducer'
import BookingHistoryReducer from '../services/reducers/BookingHistoryReducer'
import {DASHBOARD, BENEFITS, IMPROVE_PAGE, HEALTH_PAGE, PROFILE, HOME, CONSENT_ISOS, MOBILE_ACCOUNT, MEDICAL_REPORTS, MEDICAL_REPORTS_DETAILS, MEDICAL_REPORTS_DETAILS_SMS, PERSONAL_HEALTH_PROFILE, MEDICATIONS, MEDICATIONS_VIEW, MEDICATIONS_VIEW_SMS, CONSULTATIONS_HISTORY, CONSULTATIONS_HISTORY_DETAILS, CONSULTATIONS_HISTORY_DETAILS_SMS, HEALTH_RISK_ASSESSMENT_INSIGHTS, HEALTH_RISK_ASSESSMENT_GETTING_STARTED, HEALTH_RISK_ASSESSMENT_STEPS_TO_IMPROVE, HEALTH_RISK_ASSESSMENT, ANNUAL_HEALTH_CHECKUPS, NEW_ANNUAL_HEALTH_CHECKUPS, OLD_VACCINATION_FLOW, OLD_FLU_VACCINATION_FLOW, NEW_VISION_CHECKUPS, NEW_DENTAL_CHECKUPS, NEW_FLU_CHECKUPS, NEW_COVID_CHECKUPS, OLD_ANNUAL_HEALTH_CHECKUPS, OLD_VISION_CHECKS_FLOW, OLD_DENTAL_CHECKS_FLOW, OLD_COVID_FLOW, FORM, IMPROVE_CHALLENGES, INVOICES, INCLINIC, SPECIALIST_CONSULTATION,NEW_FERTILITY_TREATMENT, LAB_TESTS } from '../routes/private';
import pharmacyReducer from '../services/reducers/pharmacyReducer'
import { pharmacyInitialState, healthChecksInitialState, selectedLabtestInitialState, addNewFamilyMemberInitialState } from '../store'
import useUserDetails from '../utils/customHooks/useUserDetails';
import { isEmpty, isMobileWebPwa } from '../utils/common';
import DobAndGenderUpdate from '../components/organisms/dashboard/DobAndGenderUpdate';
import { targetName, ISOS_HOST } from '../utils/constants';
import LabTestContext from '../utils/contexts/LabTestContext';
import LabtestReducer from '../services/reducers/LabtestReducer';
import MobileWebPwaBanner from '../components/molecules/MobileWebPwaBanner';
import DownloadApp from '../components/organisms/DownloadApp';
import MobileWebPwaContext from '../utils/contexts/MobileWebPwaContext';
import addNewFamilyMemberReducer from '../services/reducers/addNewFamilyMemberReducer';
import AddNewFamilyMemberContext from '../utils/contexts/AddNewFamilyMemberContext';

const Layout = ({ history }) => {
  const bookingHistoryIntialState = { currentTabIndex: 0 }
  const [bookingHistoryStore, bookingHistorydispatch] = useReducer(BookingHistoryReducer, bookingHistoryIntialState)
  const [labtestStore,labtestDispatch] = useReducer(LabtestReducer,selectedLabtestInitialState)
  const [addNewFamilyMemberState, addNewFamilyMemberDispatch] = useReducer(addNewFamilyMemberReducer , addNewFamilyMemberInitialState) 
  const [data, updateModalContent] = useState({})
  const [navbarData, updateNavbarData] = useState({})
  const { user } = useUserDetails();
  const [profileUpdateModal, setProfileUpdateModal] = useState(false)
  const [mainState, mainReducer] = combineReducers({
    pharmacy: [pharmacyReducer, pharmacyInitialState],
    healthChecks: [ekincareReducer, healthChecksInitialState]
  })
  const [globalState, dispatch] = useReducer(mainReducer, mainState)
  const [showMobileWebPwaBanner] = useContext(MobileWebPwaContext)

  const privacyNoticeAccepted = ((targetName === 'isos' && window.localStorage.getItem('privacyNoticeAgree') === 'true') || (window.location.host === ISOS_HOST && window.localStorage.getItem('privacyNoticeAgree') === 'true') || targetName !== 'isos' 
  )
  
  const updateContent = (newData) => {
    updateModalContent({...data, ...newData})
  }

  useEffect(() => {
    if (Object.keys(user)?.length === 0) return;
    if ((isEmpty(user.gender) || isEmpty(user.date_of_birth)) && privacyNoticeAccepted) setProfileUpdateModal(true)
  }, [user,privacyNoticeAccepted])

  const hasPrescriptionPending = window.localStorage.getItem('HAS_PRESCRIPTION_PENDING') === "TRUE";

  useEffect(() => {
    const shouldDisableMoengageFn = window.shouldDisableMoengage;
    const isEkincareAppHostFn = window.isEkincareAppHost;

    if (typeof shouldDisableMoengageFn === 'function' && typeof isEkincareAppHostFn === 'function') {
      if (!shouldDisableMoengageFn() && isEkincareAppHostFn()) {
        window.moeInit();
      }
    }
  }, []);
  
  useEffect(() => {
    if(!privacyNoticeAccepted && user?.id){
      history.push(CONSENT_ISOS)
    }
  }, [history.location.pathname,privacyNoticeAccepted,user?.id])

  const SHOW_BOTTOM_BAR_PATHS = [DASHBOARD, HOME, HEALTH_PAGE, BENEFITS, IMPROVE_PAGE, PROFILE, MOBILE_ACCOUNT]

  const SHOW_APP_DOWNLOAD_SCREEN = [HEALTH_PAGE, MEDICAL_REPORTS, MEDICAL_REPORTS_DETAILS, MEDICAL_REPORTS_DETAILS_SMS, PERSONAL_HEALTH_PROFILE, MEDICATIONS, MEDICATIONS_VIEW, MEDICATIONS_VIEW_SMS, CONSULTATIONS_HISTORY,  CONSULTATIONS_HISTORY_DETAILS, CONSULTATIONS_HISTORY_DETAILS_SMS, HEALTH_RISK_ASSESSMENT_INSIGHTS, HEALTH_RISK_ASSESSMENT_GETTING_STARTED, HEALTH_RISK_ASSESSMENT_STEPS_TO_IMPROVE, HEALTH_RISK_ASSESSMENT]
  const SHOW_BACKGROUND_COLOR = [ANNUAL_HEALTH_CHECKUPS, NEW_ANNUAL_HEALTH_CHECKUPS, NEW_VISION_CHECKUPS, NEW_DENTAL_CHECKUPS, NEW_FLU_CHECKUPS, NEW_COVID_CHECKUPS, OLD_ANNUAL_HEALTH_CHECKUPS, OLD_VISION_CHECKS_FLOW, OLD_DENTAL_CHECKS_FLOW, OLD_COVID_FLOW, OLD_FLU_VACCINATION_FLOW, OLD_VACCINATION_FLOW, MEDICAL_REPORTS, MEDICATIONS, IMPROVE_CHALLENGES, CONSULTATIONS_HISTORY, INVOICES, INCLINIC,SPECIALIST_CONSULTATION,NEW_FERTILITY_TREATMENT,LAB_TESTS]

  const HIDE_NAVBAR = [FORM,CONSENT_ISOS]

  return (
    <GlobalContext.Provider value = {[globalState, dispatch]}>
      <ModalContext.Provider value={updateContent}>
        <NavbarContext.Provider value={{data: navbarData, updateData: updateNavbarData}}>
          <LabTestContext.Provider value={[labtestStore,labtestDispatch]}>
            <AddNewFamilyMemberContext.Provider value={[addNewFamilyMemberState, addNewFamilyMemberDispatch]}>
              <BookingHistoryContext.Provider value={[bookingHistoryStore, bookingHistorydispatch]}>
                {!hasPrescriptionPending && <Navbar hideNavbar={HIDE_NAVBAR.indexOf(history.location.pathname) !== -1} />}
                <div style={SHOW_BACKGROUND_COLOR.indexOf(history.location.pathname) !== -1 ? { backgroundColor: "#F5F7F9" } : null}>
                  <Main hideMenu={SHOW_BOTTOM_BAR_PATHS.indexOf(history.location.pathname) !== -1} mt={['0px', '100px !important']}>
                    <Switch>
                      {
                        (isMobileWebPwa() && SHOW_APP_DOWNLOAD_SCREEN.indexOf(history.location.pathname) !== -1) ?
                          <>
                            {showMobileWebPwaBanner && <MobileWebPwaBanner />}
                            <DownloadApp />
                          </> : null
                      }
                      {privateRoutes(showMobileWebPwaBanner)}
                    </Switch>
                    {profileUpdateModal && <DobAndGenderUpdate customer={user} profileUpdateModal={profileUpdateModal} closeProfileModal={() => setProfileUpdateModal(false)} />}
                  </Main>
                </div>
                {SHOW_BOTTOM_BAR_PATHS.indexOf(history.location.pathname) !== -1 && !hasPrescriptionPending ? <BottomBar /> : null}
                <LayoutModalsConsumer {...data} updateModal={updateContent}/>
              </BookingHistoryContext.Provider>
            </AddNewFamilyMemberContext.Provider>
          </LabTestContext.Provider>
        </NavbarContext.Provider>
      </ModalContext.Provider>  
    </GlobalContext.Provider>
  )
}


export default Layout;